import { useState, useEffect } from 'react'
import { supabase } from '../lib/db'

function Work() {
    document.title = "Work — Pirabaa Segar";

    const [work, setWork] = useState([])

    useEffect(() => {
        const getWork = async () => {
            const { data } = await supabase
                .from('work')
                .select()
                .order('id', { ascending: true })
            if (data) {
                setWork(data)
            }
        }

        getWork()
    }, [])

    return (
        <main>
            <section id="hero" className="hero page-hero p-0 pt-2">
                <div className="hero-text align-content-center">
                    <div className="container">
                        <div className="row flex-lg-row flex-column gap-4 align-content-center align-content-lg-start">
                            <div className="col-lg-auto col-10 text-center text-lg-start">
                                <h1 className="display-4 brand-accent-color">Featured Work</h1>
                                <p className="my-0">
                                    Discover my featured work, where creativity meets functionality to bring innovative ideas to life. Explore the diverse range of work I’ve crafted, designed to elevate your digital experience and inspire new possibilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="work" className="work mb-5">
                <div className="container">
                    <div className='row flex-lg-row flex-column row-gap-4'>
                        {work.map((work) => (
                            <div className='col-4' key={work.id}>
                                <div className="work-card position-relative overflow-hidden w-100">
                                    <div className="work-image" style={{ backgroundImage: `url(${work.image_url})` }}></div>
                                    <div className='content'>
                                        <div>
                                            <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 work-type'>Featured Work</h3>
                                            <h3 className='m-0 fw-bold'>{work.name}</h3>
                                        </div>
                                        <a href={work.url} target="_blank" rel="noopener noreferrer">
                                            <i className="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Work 