import React from 'react';

function Error() {
    document.title = "404 Error — pirabaa.ca";
    return (
        <>
            <main>
                <section id='hero' className="hero error-page-hero p-0">
                    <div className="hero-text align-content-center">
                        <div className='container'>
                            <div className='row flex-lg-row flex-column gap-4 justify-content-center'>
                                <div className='col-auto text-center'>
                                    <h1 className='display-1 mb-0'>404</h1>
                                    <h3 className='fw-bold'>Oops, page not found!</h3>
                                    <p>
                                        The page you are looking for does not exist or has been moved.
                                    </p>
                                    <a href="/" className='btn brand-accent-bg border-0 text-white rounded-2 custom-btn' >
                                        Go to Homepage
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Error;