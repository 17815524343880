import { useState, useEffect } from 'react'
import { supabase } from '../lib/db'

function Home() {
  document.title = "Pirabaa Segar — Frontend Engineer";

  const [work, setWork] = useState([])
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getWork = async () => {
      const { data } = await supabase
        .from('work')
        .select()
        .order('id', { ascending: true })
        .limit(3);
      if (data) {
        setWork(data)
      }
    }

    const getBlogs = async () => {
      const { data } = await supabase
        .from('blogs')
        .select()
        .order('id', { ascending: true })
        .limit(2);
      if (data) {
        setBlogs(data);
      }
    };

    getWork();
    getBlogs();
  }, [])
  return (
    <>
      <main>
        <section id='hero' className="hero p-0">
          <div className="hero-text align-content-center">
            <div className='container'>
              <div className='row flex-lg-row flex-column align-items-center justify-content-between gap-5'>
                <div className='col-8 col-lg-7 d-flex justify-content-center flex-column text-center text-lg-start align-items-start gap-4'>
                  <h1 className='display-4 m-0'><span className='brand-accent-color'>Hi, I'm Pirabaa</span><br></br>a frontend engineer<br></br>and graphic designer</h1>
                  <p className='m-0'>
                    Based in Toronto, I work as a frontend engineer, and graphic designer. I have 7 years of experience and design high-quality digital products that will let your business thrive.
                  </p>
                  <div className='d-flex flex-lg-row flex-column align-items-center gap-3 gap-lg-0 w-100'>
                    <a href="/contact" className='btn brand-accent-bg d-flex align-items-center justify-content-between border-0 text-white rounded-2 custom-btn fw-normal'>
                      <span>Let's Talk</span>
                    </a>
                    <a href="/portfolio" className='btn d-flex align-items-center justify-content-between border-0 custom-btn fw-semibold gap-3 text-black'>
                      <span>Check my Work</span><i class="bi bi-chevron-right arrow-animation"></i>
                    </a>
                  </div>
                </div>
                <div className='col-lg-4 col-9 d-flex align-items-center justify-content-center'>
                  <img src='https://ca.slack-edge.com/TCKE4QSG5-U05QJEFV668-0345ac48681b-512' className='rounded-circle img-fluid' alt='personal'></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Work" className="Work">
          <div className="section-title">
            <div className='container'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>Featured Work</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className='row flex-lg-row flex-column gap-4'>
              {work.map((work) => (
                <div className='col' key={work.id}>
                  <div className="work-card position-relative overflow-hidden w-100">
                    <div className="work-image" style={{ backgroundImage: `url(${work.image_url})` }}></div>
                    <div className='content'>
                      <div>
                        <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 work-type'>Featured Work</h3>
                        <h3 className='m-0 fw-bold'>{work.name}</h3>
                      </div>
                      <a href={work.url} target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-arrow-down-right-circle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="blog" className="blog">
          <div className="section-title">
            <div className='container'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>Featured Blogs</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className='row flex-lg-row flex-column gap-4'>
              {blogs.map((blog) => (
                <div className='col' key={blog.id}>
                  <div className="blog-card position-relative overflow-hidden w-100">
                    <div className="blog-image" style={{ backgroundImage: `url(${blog.image_url})` }}></div>
                    <div className='content'>
                      <div>
                        <div className="blog-info">
                          <span>{blog.category}</span>
                          <span>{new Date(blog.publish_date).toLocaleDateString()}</span>
                        </div>
                        <h3 className='m-0 fw-bold'>
                          {blog.title}
                        </h3>
                      </div>
                      <a href={blog.url} target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-arrow-down-right-circle"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="about" className="about section-bg mb-5">
          <div className="section-title pb-4">
            <div className='container'>
              <div className='row justify-content-center justify-content-lg-start'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>About Me</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <p className="mb-0 lh-lg text-center text-lg-start">
                Based in Toronto, I work as a web designer, developer, and student. I've worked with companies for a number of years, developing powerful, useful brand identities and digital experiences that convert well. I take great satisfaction in using top-notch software and writing clear, reliable code to create and develop faultless websites, designs, and brand identities. My work is visually stunning without sacrificing performance. Who doesn't love a website that is both aesthetically pleasing and incredibly functional?
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
