import { useState, useEffect } from 'react'
import { supabase } from '../lib/db'

function Projects() {
    document.title = "Blogs — Pirabaa Segar";

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const getBlogs = async () => {
            const { data, error } = await supabase.from('blogs').select();

            if (error) {
                console.error('Error fetching blogs:', error);
                return;
            }

            if (data) {
                setBlogs(data);
            }
        };

        getBlogs();
    }, [])

    return (
        <main>
            <section id="hero" className="hero page-hero p-0 pt-2">
                <div className="hero-text align-content-center">
                    <div className="container">
                        <div className="row flex-lg-row flex-column gap-4 align-content-center align-content-lg-start">
                            <div className="col-lg-auto col-10 text-center text-lg-start">
                                <h1 className="display-4 brand-accent-color">Featured Blogs</h1>
                                <p className="my-0">
                                    Discover my featured projects, where creativity meets functionality to bring innovative ideas to life. Explore the diverse range of work I’ve crafted, designed to elevate your digital experience and inspire new possibilities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog" className="blog mb-5 py-0">
                <div className="container">
                    <div className='row flex-lg-row flex-column gap-4'>
                        {blogs.map((blog) => (
                            <div className='col' key={blog.id}>
                                <div className="blog-card position-relative overflow-hidden w-100">
                                    <div className="blog-image" style={{ backgroundImage: `url(${blog.image_url})` }}></div>
                                    <div className='content'>
                                        <div>
                                            <div className="blog-info">
                                                <span>{blog.category}</span>
                                                <span>{new Date(blog.publish_date).toLocaleDateString()}</span>
                                            </div>
                                            <h3 className='m-0 fw-bold'>
                                                {blog.title}
                                            </h3>
                                        </div>
                                        <a href={blog.url} target="_blank" rel="noopener noreferrer">
                                            <i className="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Projects 