import React from 'react';

function ContactForm() {
  document.title = "Contact — Pirabaa Segar";
  
  return (
    <>
      <main>
        <section id="hero" className="hero page-hero p-0 pt-2">
          <div className="hero-text align-content-center">
            <div className="container">
              <div className="row flex-lg-row flex-column gap-4 align-content-center align-content-lg-start">
                <div className="col-lg-auto col-10 text-center text-lg-start">
                  <h1 className="display-4 brand-accent-color">Get in Touch</h1>
                  <p className="my-0">
                    Get in touch! Whether you need advice, have a project in mind, or just want to connect, I’m here to help bring your ideas to life with innovative web design, development solutions, and creative collaboration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact pt-4 pt-lg-0">
          <form>
            <div className="container">
              <div className="row flex-lg-row flex-column gap-3 gap-lg-0">
                <div className="row flex-lg-row flex-column pe-0">
                  <div className="col pe-0">
                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col pe-0">
                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="5"
                    required
                  ></textarea>
                </div>
                <div className="mt-2">
                  <input
                    className="btn brand-accent-bg d-flex align-items-center justify-content-between border-0 text-white rounded-2 custom-btn px-4 fw-normal m-auto m-lg-0"
                    type="submit"
                    value="Send Message"
                  />
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>
    </>
  );
}

export default ContactForm;