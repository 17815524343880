import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 80);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <header className={`header w-100 ${isScrolled ? 'scrolled' : ''}`} id="header">
            <div className="container d-flex justify-content-between align-items-center">
                <a href="/">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 696.8 299.78" width={'5rem'} className='logo'>
                        <path d="M347.16,299.28l254.9-2.8c118.93-2.67,130.48-167.09,8.44-175.71l-197.34-2.74c.23,21.07-7.6,41.37-16.72,59.96h193.09c4.04,0,15.2,11.41,15.95,16.11,2.22,13.81-2.71,22.85-16.04,27.02l-240.47.71" />
                        <path d="M267.48,147.32c17.34-2.34,33.94-15.77,34.2-34.23.2-14.01-2.4-23.04.37-38.55C316.09-4.19,407.32,1.89,468.17.87c71.31-1.2,142.76,1.03,214.06.07l-2.23,5.42-1.98,71.96-276.41.98c-14.18,5.39-7.84,16.46-7.83,26.89.09,76.37-54.17,108.89-123.53,116.39l-194.46.02v76.68H.5v-151.96h266.98Z" style={{ fill: "#008afc" }} />
                        <path d="M327.63,2.99l-56.51-2.21C201.1-1.83,72.3,3.31,2.35,1.14l.03,76.51,278.86-.35c1.3-16.37-4.05-16.85,15.52-47.48" />
                    </svg>
                </a>
                <nav id="navbar" className="navbar order-last p-0" style={{ float: "right" }}>
                    <ul className='m-0 p-0 d-flex list-unstyled align-items-center d-none d-lg-flex fw-medium'>
                        <li>
                            <a href="/" className={`d-flex align-items-center justify-content-between ${isActive('/')}`}>
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="/work" className={`d-flex align-items-center justify-content-between ${isActive('/work')}`}>
                                Work
                            </a>
                        </li>
                        <li>
                            <a href="/blog" className={`d-flex align-items-center justify-content-between ${isActive('/blog')}`}>
                                Blog
                            </a>
                        </li>
                        <li>
                            <a href="/contact" className={`btn brand-accent-bg d-flex align-items-center justify-content-between border-0 rounded-2 custom-btn px-4 fw-normal ${isActive('/contact')} text-white`}>
                                Get in Touch
                            </a>
                        </li>
                    </ul>
                    <i className={`bi mobile-nav-toggle d-lg-none d-block ${isMobileNavVisible ? 'bi-x' : 'bi-list'}`} onClick={() => setIsMobileNavVisible(!isMobileNavVisible)} />
                </nav>
                <nav id="mobile-nav" className={`mobile-nav position-fixed bg-white w-75 text-center d-lg-none d-flex flex-column justify-content-between ${isMobileNavVisible ? 'mobile-nav-show' : ''}`}>
                    <ul className='p-0 list-unstyled mb-0 pe-4 pt-4'>
                        <li className='d-lg-none d-block'>
                            <a href="/" className={`d-flex align-items-end justify-content-end text-decoration-none brand-accent-color`}>
                                Home
                            </a>
                        </li>
                        <li className='d-lg-none d-block'>
                            <a href="/#projects" className={`d-flex align-items-end justify-content-end text-decoration-none`}>
                                Projects
                            </a>
                        </li>
                        <li className='d-lg-none d-block'>
                            <a href="/#blog" className={`d-flex align-items-end justify-content-end text-decoration-none`}>
                                Blog
                            </a>
                        </li>
                    </ul>
                    <div>
                        <div className='seperator'></div>
                        <a href="/contact" className='d-flex align-items-center justify-content-between border-0 bg-white cta-button mb-4 me-4 float-end brand-accent-color border-bottom p-0 brand-accent-border'>
                            <span>Get in Touch</span>
                        </a>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;